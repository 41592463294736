@tailwind base;
@tailwind components;
@tailwind utilities;

/* This is so TailwindCSS doesn't apply borders to MUI text boxes */
[type="text"]:focus,
[type="path"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="textarea"]:focus,
textarea:focus,
input:where(:not([type])):focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: initial !important;
}

html,
body,
#root {
  /* height: 100%; */
  min-height: 100vh;
  margin: 0;
  background-color: #e2e8f0;
}

.makesure {
  min-height: 100%;
}

.editable-row {
  background-color: #fcfffa;
}

.non-editable-cell {
  cursor: default;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.depth-1 {
  background-color: #fdfdfd; /* Light gray for top level */
}

.depth-2 {
  background-color: #f3f3f3; /* A slightly darker shade for the second level */
}

.depth-3 {
  background-color: #fdfdfd; /* Even darker for the third level */
}

.highlighted-row {
  background-color: #ffec80;
}

.approved-row {
  background-color: #d4edda;
}

.nonapproved-row {
  background-color: #f8d7da;
}

.z-top-custom {
  z-index: 500;
}
